module.exports = [{
      plugin: require('/Users/casper/Realm/Summitech/summit-vision/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/casper/Realm/Summitech/summit-vision/node_modules/gatsby-plugin-accessibilityjs/gatsby-browser'),
      options: {"plugins":[],"injectStyles":"\n          .accessibility-error {\n            box-shadow: 0 0 3px 1px #f00;\n            background-color: rgba(255, 0, 0, 0.25);\n            position: relative;\n          }\n          .accessibility-error:before {\n            content: \"A11Y\";\n            position: absolute;\n            top: 0;\n            left: 0;\n            color: #fff;\n            font-size: 10px;\n            background-color: rgba(255, 0, 0, 0.5);\n            transform: translateY(-100%);\n          }\n        "},
    },{
      plugin: require('/Users/casper/Realm/Summitech/summit-vision/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
